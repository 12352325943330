import { type GetStaticProps } from 'next'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import { useRouter } from 'next/router'
import { useContext, useMemo } from 'react'

import { type NextPageWithLayout } from '@promova/ui/types'
import useRobotsNoIndex from '@promova/utils/customHooks/useRobotsNoIndex'

import { DEFAULT_LOCALE, HOME_PATH, SITE_URL } from '@constants_folder/common'
import { ONE_WEEK } from '@constants_folder/staticPages'
import {
  MAIN_PAGE,
  PROMOVA_IN_NUMBERS_URL,
} from '@constants_folder/strapi_urls'

import {
  type MainLandingPageTypes,
  type StatsSectionTypes,
} from '@_types/landings'

import getAvailableLocales, {
  type LocalizationsTypes,
} from '@strapi/getAvailableLocales'
import { fetchStrapi } from '@strapi/strapi'

import useMainRedirectUrl from '@hooks/useMainRedirectUrl'

import { createLandingSchema } from '@utils/createSchema'
import loadCatalog from '@utils/loadCatalog'

import ErrorLayout from '@templates/ErrorLayout/ErrorLayout'
import LandingNavigation from '@templates/LandingNavigation/LandingNavigation'
import MainLanding from '@templates/MainLandingV3/MainLanding'

import { AuthContext } from '@modules/Auth/AuthProvider'
import SeoLayout from '@modules/Seo/SeoLayout'

import Loader from '@elements/Loader/Loader'

type Props = {
  page: MainLandingPageTypes
  stats: StatsSectionTypes
  error?: any
  host?: string
  translation?: Record<string, any>
  availableLocales?: string[]
}

const Home: NextPageWithLayout<Props> = ({
  page,
  stats,
  error,
  host,
  availableLocales = [],
}) => {
  const { user, loading } = useContext(AuthContext)
  const { asPath, locale } = useRouter()

  useMainRedirectUrl()
  const seo = page?.seo ?? {}
  seo.metaRobots = useRobotsNoIndex(seo?.metaRobots)

  const schema = useMemo(
    () =>
      createLandingSchema({
        title: page?.title,
        description: page?.seo?.metaDescription,
        url: asPath,
        faq: page?.faq,
        locale,
      }),
    [asPath, locale, page?.faq, page?.seo?.metaDescription, page?.title]
  )

  const structuredData = seo?.structuredData || schema

  if (typeof window !== 'undefined' && (loading || !page || user))
    return <Loader isLoading />

  return (
    <ErrorLayout isError={error}>
      <SeoLayout seo={seo} hostname={host} structuredData={structuredData}>
        <LandingNavigation availableLocales={availableLocales}>
          <main>
            <MainLanding page={page} stats={stats} />
          </main>
        </LandingNavigation>
      </SeoLayout>
    </ErrorLayout>
  )
}

export default Home

type StrapiResponse<T> = {
  data: {
    attributes: T & {
      localizations: LocalizationsTypes
    }
  }
  error: boolean
}

const DEFAULT_FETCH_STRAPI_OPTIONS = {}
const DEFAULT_FETCH_STRAPI_ATTEMPT = 0
const DEFAULT_FETCH_STRAPI_IS_PROXY = false
const IS_FALLBACK_FOR_DEFAULT_LOCALE = false

const populateTemplate = {
  slides: {
    populate: ['image', 'flag', 'video'],
  },
  stats: {
    populate: '*',
  },
  approachList: {
    populate: '*',
  },
  faq: {
    populate: {
      faq: {
        populate: ['image'],
      },
    },
  },
  mainImage: {
    populate: '*',
  },
  reviewsItems: {
    populate: {
      author: {
        populate: ['avatar', 'flag'],
      },
    },
  },
  seo: {
    populate: {
      structuredData: {
        populate: '*',
      },
      metaImage: {
        populate: '*',
      },
      metaSocial: { populate: ['image'] },
      author: {},
    },
  },
  breadcrumbs: { populate: '*' },
  sectionVideoPreview: { populate: '*' },
  heroCards: {
    populate: ['image'],
  },
  mediaTopMarquee: { populate: '*' },
  mediaBottomMarquee: { populate: '*' },
  mediaRecommendedResources: { populate: '*' },
  heroSectionGif: { populate: '*' },
  watchVideoSpinner: { populate: ['circle', 'center'] },
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale, defaultLocale } = context

  const localizationsPopulate = locale !== defaultLocale && populateTemplate
  await loadCatalog(locale)

  const mainPageRes: StrapiResponse<MainLandingPageTypes> = await fetchStrapi(
    MAIN_PAGE,
    {
      locale,
      populate: {
        ...populateTemplate,
        localizations: { populate: localizationsPopulate },
      },
    },
    DEFAULT_FETCH_STRAPI_OPTIONS,
    DEFAULT_FETCH_STRAPI_ATTEMPT,
    DEFAULT_FETCH_STRAPI_IS_PROXY,
    IS_FALLBACK_FOR_DEFAULT_LOCALE
  )

  const availableLocales = getAvailableLocales(
    {
      data: [
        {
          attributes: {
            localizations: mainPageRes.data?.attributes?.localizations,
          },
        },
      ],
    },
    DEFAULT_LOCALE
  )

  const isCurrentLocaleMissing = Boolean(
    locale && locale !== DEFAULT_LOCALE && !availableLocales?.includes(locale)
  )
  if (isCurrentLocaleMissing) availableLocales.push(locale as string)

  if (isCurrentLocaleMissing && !mainPageRes.data) {
    if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
      return { notFound: true }
    }

    return {
      redirect: {
        destination: HOME_PATH,
        permanent: true,
      },
    }
  }

  const statsRes = await fetchStrapi(PROMOVA_IN_NUMBERS_URL, {
    locale,
  })

  return {
    props: {
      page: mainPageRes?.data?.attributes,
      stats: statsRes?.data?.attributes,
      error: mainPageRes.error,
      host: SITE_URL,
      translation: true,
      availableLocales,
    },
    revalidate: ONE_WEEK,
  }
}
