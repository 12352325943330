import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import '@styles/globals.css'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { de, en, es, fr, it, pl, pt, tr, uk } from 'make-plural/plurals'
import { type NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import {
  type ReactElement,
  type ReactNode,
  Suspense,
  lazy,
  useEffect,
  useRef,
  useState,
} from 'react'
import { hotjar } from 'react-hotjar'
import { Provider } from 'react-redux'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { store } from '@reduxStore/store'

import { HOTJAR_SITE_ID, HOTJAR_SV } from '@constants_folder/keys'
import {
  DISCOUNT_TIMER_NAME,
  EXTRA_DISCOUNT_TIMER_NAME,
} from '@constants_folder/sales_page'
import { GROWTH_BOOK_PAYLOAD } from '@constants_folder/storageKeys'

import { useSaveEntryPoint, useSaveUtmsToLS } from '@hooks/saveEntryPoint'
import useBlockAnalytics from '@hooks/useBlockAnalytics'
import { useRedirectToDefaultLocale } from '@hooks/useRedirectToDefaultLocale'
import useWebViewMode from '@hooks/useWebViewMode'

import { sendJoinABTestOncePerSession } from '@utils/analytics'
import useLocale from '@utils/customHooks/useLocales'
import { ImpactIdentify } from '@utils/impact'
import loadCatalog from '@utils/loadCatalog'

import NoConnectionAlert from '@features/NoConnectionAlert/NoConnectionAlert'

import AuthProvider from '@modules/Auth/AuthProvider'
import WhiteNoiseProvider from '@modules/WhiteNoiseProvider/WhiteNoiseProvider'

import CommonHead from '@elements/CommonHead'
import CommonScripts from '@elements/CommonScripts'
import ErrorBoundary from '@elements/ErrorBoundary/ErrorBoundary'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('uk', { plurals: uk })
i18n.loadLocaleData('es', { plurals: es })
i18n.loadLocaleData('es-419', { plurals: es })
i18n.loadLocaleData('pt', { plurals: pt })
i18n.loadLocaleData('fr', { plurals: fr })
i18n.loadLocaleData('tr', { plurals: tr })
i18n.loadLocaleData('it', { plurals: it })
i18n.loadLocaleData('de', { plurals: de })
i18n.loadLocaleData('pl', { plurals: pl })

const ReactQueryDevtoolsProduction = lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [showQueryDevtools, setShowQueryDevtools] = useState(false)
  const router = useRouter()
  const { query } = router

  const firstRender = useRef<string | undefined>('')
  const locale = router?.locale || router?.defaultLocale

  if (pageProps?.translation && firstRender.current !== locale) {
    loadCatalog(locale)
    firstRender.current = locale
  }

  useBlockAnalytics()

  // init hotjar
  useEffect(() => {
    hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_SV)
  }, [])

  // add toggle devtools on preview and dev mode
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
    ) {
      window.toggleDevtools = () => setShowQueryDevtools((old) => !old)
    }
  }, [])

  // clear timer
  useEffect(() => {
    const clearTimer = query?.clear_timer

    if (clearTimer) {
      localStorage.removeItem(DISCOUNT_TIMER_NAME)
      localStorage.removeItem(EXTRA_DISCOUNT_TIMER_NAME)
    }
  }, [query?.clear_timer])

  // add store to window to access it outside components
  // and avoid creating dependency cycle
  useEffect(() => {
    ;(window as any)._store = store
  }, [])

  // load dictionaries
  useLocale()

  // save entry point
  useSaveEntryPoint()

  // save utms to LC
  useSaveUtmsToLS()

  // save webview mode from query to sessionStorage
  useWebViewMode()

  const growthBook = new GrowthBook({
    apiHost: process.env.NEXT_PUBLIC_GROWTH_BOOK_API_KEY,
    clientKey: process.env.NEXT_PUBLIC_GROWTH_CLIENT_KEY,
    enableDevMode: process.env.NEXT_PUBLIC_LIVE_MODE !== 'true',
    remoteEval: true,
    // fire a callback whenever an experiment is evaluated for a user
    trackingCallback: (experiment, result) => {
      sendJoinABTestOncePerSession({
        key: experiment.key,
        value: result?.value?.value,
        split_analytics: result?.value?.split_analytics,
      })
    },
  })

  growthBook.updateAttributes({
    platform: 'web',
  })

  growthBook
    .init({
      streaming: false,
    })
    .then(() => {
      if (typeof window === 'undefined') return

      const sessionGBConfig = sessionStorage.getItem(GROWTH_BOOK_PAYLOAD)

      if (sessionGBConfig) {
        try {
          const payload = JSON.parse(sessionGBConfig)
          const featuresMap = new Map(
            Object.entries(payload?.features || {}).map(([key, value]) => [
              key,
              (value as any).defaultValue !== undefined
                ? (value as any).defaultValue
                : value,
            ])
          )
          growthBook.setForcedFeatures(featuresMap)
        } catch {
          // silent error
        }
      }
    })

  useEffect(() => {
    ;(window as any).growthBook = growthBook
  }, [])

  useRedirectToDefaultLocale()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <GrowthBookProvider growthbook={growthBook}>
          <I18nProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <Hydrate state={pageProps?.dehydratedState}>
                  <WhiteNoiseProvider>
                    <CommonHead
                      availableLocales={pageProps?.availableLocales}
                    />
                    <CommonScripts />
                    <NoConnectionAlert />
                    <ImpactIdentify />
                    {getLayout(<Component {...pageProps} />)}
                    <ReactQueryDevtools />
                    {showQueryDevtools && (
                      <Suspense fallback={null}>
                        <ReactQueryDevtoolsProduction />
                      </Suspense>
                    )}
                  </WhiteNoiseProvider>
                </Hydrate>
              </AuthProvider>
            </QueryClientProvider>
          </I18nProvider>
        </GrowthBookProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default MyApp

MyApp.getInitialProps = async (appContext: AppContext) => {
  const res = appContext?.ctx?.res

  if (process.env.NEXT_PUBLIC_LIVE_MODE === 'true')
    res?.setHeader(
      'Cache-Control',
      'public, max-age=0, s-maxage=3600, stale-while-revalidate=86400'
    )

  return {}
}
