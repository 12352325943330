import createClient from 'openapi-fetch'

import { type UseQueryOptionsWithParams } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

import { useAuthContext } from '@modules/Auth/AuthProvider'

import { type paths } from './schema'

const keeperPath = '/v1/profiles/keeper/{key}'

type KeeperConfigV1 = {
  key: string
  token: string
}

const API_HOST = process.env.NEXT_PUBLIC_API_HOST || ''

const openApiClient = createClient<paths>({ baseUrl: API_HOST })

export const getDataFromKeeperV1 = async ({ key, token }: KeeperConfigV1) => {
  try {
    const res = await openApiClient.GET(keeperPath, {
      params: { path: { key } },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    return res?.data || null
  } catch {
    return null
  }
}

export const useGlobalUserKeeper = ({
  params,
  reactQuery,
}: UseQueryOptionsWithParams<
  paths[typeof keeperPath]['get'],
  Awaited<ReturnType<typeof getDataFromKeeperV1>>
>) => {
  const key = params?.path?.key
  const { globalUser } = useAuthContext()
  const userId = globalUser?.uid

  return useQueryFactory([keeperPath, key, userId], async () => {
    const token = await globalUser?.getIdToken()
    if (!token) {
      return null
    }

    return getDataFromKeeperV1({ key, token })
  })(reactQuery as any)
}
