import { t } from '@lingui/macro'

import { LandingsRoutes } from '@constants_folder/routes'

import { type NavigationLink } from '@_types/navigation'

import starsAnimation from '@images/navigation/stars.json'

export const mainLinks: NavigationLink[] = [
  {
    id: LandingsRoutes.forBusiness,
    route: LandingsRoutes.forBusiness,
    label: t`For Business`,
  },
  {
    id: LandingsRoutes.groupLessons,
    route: LandingsRoutes.groupLessons,
    label: t`Group lessons`,
  },
  {
    id: LandingsRoutes.onlineEnglishTutors,
    route: LandingsRoutes.onlineEnglishTutors,
    label: t`English tutors`,
  },
  {
    id: LandingsRoutes.languageLearningApp,
    route: LandingsRoutes.languageLearningApp,
    label: t`App`,
    animationData: starsAnimation,
  },
]
