import { useRouter } from 'next/router'
import { useEffect } from 'react'
import sourcebuster from 'sourcebuster'

import { useAppSelector } from '@reduxStore/hooks'

import { UTMS_FROM_SOURCEBUSTER, observeUtms } from '@constants'
import { FIRST_SESSION_PAGE_VIEW_SEND } from '@constants_folder/storageKeys'

import { PinterestEventNames, SnapChatEventNames } from '@_types/analytics'

import {
  sendPinterestAnalytics,
  sendSnapPixelAnalytics,
  trackPageView,
} from '@utils/analytics'

export const usePageView = () => {
  const { asPath, query, isReady } = useRouter()
  const isAmplitudeInit = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const isSourceBusterInit = useAppSelector(
    (state) => state.sourceBuster.sourceBusterInited
  )

  useEffect(() => {
    if (isAmplitudeInit && isSourceBusterInit && isReady) {
      const params: Record<string, any> = {}
      observeUtms.forEach((utm: string) => {
        if (query[utm]) {
          params[utm] = query[utm]
        }
      })

      const isFirstSessionViewSend =
        sessionStorage.getItem(FIRST_SESSION_PAGE_VIEW_SEND) === 'true'

      // add initial utms once per session
      if (!isFirstSessionViewSend) {
        Object.entries(UTMS_FROM_SOURCEBUSTER).forEach(([key, value]) => {
          params[`initial_${value}`] = sourcebuster.get.first[key]
        })
        sessionStorage.setItem(FIRST_SESSION_PAGE_VIEW_SEND, 'true')
      }

      if (sourcebuster?.get) {
        Object.entries(UTMS_FROM_SOURCEBUSTER).forEach(([key, value]) => {
          const paramValue = sourcebuster?.get.current[key]
          if (paramValue && paramValue !== '(none)' && !params[`${value}`]) {
            params[`${value}`] = paramValue
          }
        })
      }

      trackPageView({
        isFirstSessionViewSend,
        data: { page_path: asPath, ...params },
      })

      sendSnapPixelAnalytics({ eventName: SnapChatEventNames.PAGE_VIEW })

      sendPinterestAnalytics({ eventName: PinterestEventNames.PAGE_VIEW })
    }
  }, [asPath, isAmplitudeInit, isSourceBusterInit, isReady])
}
