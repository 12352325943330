import {
  COURSES_ONBOARDING,
  INTERNAL_SETTINGS,
  INTERNAL_TUTORS,
  LESSONS_ONBOARDING,
  SHORT_ONBOARDING,
  TUTORING_PRICING,
} from './flow_ids'

export enum TermsRoutes {
  terms = '/terms',
  platformGuidelines = '/terms/platform-guidelines',
  privacyPolicy = '/terms/privacy-policy',
  refund = '/terms/refund',
  subscriptionTerms = '/terms/subscription-terms',
  termsAndConditions = '/terms/terms-and-conditions',
  cookie = '/terms/cookie-policy',
  doNotSellMyPersonalInformation = '/terms/do-not-sell-my-personal-information',
}

export enum LandingsRoutes {
  groupLessons = '/group-lessons',
  conversationClub = '/conversation-club',
  languageLearningApp = '/language-learning-app',
  onlineEnglishTutors = '/online-english-tutors',
  onlineEnglishClasses = '/online-english-classes',
  learnEnglishOnline = '/learn-english-online',
  testYourEnglishLevel = '/test-your-english-level',
  onlineLanguageCourse = '/course/online-language-course',
  howToPronounce = '/how-to-pronounce',
  ourEditorialProcess = '/our-editorial-process',
  whoWeAre = '/who-we-are',
  businessEnglishOnlineCourses = '/page/business-english-online-courses',
  corporateEnglishOnlineCourses = '/page/business-english-online-courses', // same as businessEnglishOnlineCourses
  forBusiness = '/page/promova-for-business',
}

export enum PagesRoutes {
  myPlan = '/my-plan',
  courses = '/course',
  coursesStartOnboarding = '/course/start-onboarding',
  coursesOnboarding = '/courses-onboarding',
  lessonsOnboarding = '/lessons-onboarding',
  myPlanStartOnboarding = '/my-plan/start-onboarding',
  myTutor = '/lessons/1x1/upcoming',
  myTutorPastLessons = '/lessons/1x1/past',
  groupLessons = '/lessons/group/upcoming',
  groupLessonsPast = '/lessons/group/past',
  groupLessonsSchedule = '/lessons/group/schedule',
  tutoringWelcome = '/tutoring-welcome',
  contactUs = '/contact-us',
  signIn = '/sign-in',
  signUp = '/sign-up',
  tests = '/tests',
  groupLessonsPricing = '/group-lessons/pricing',
  tutoringPricing = '/tutoring/pricing',
  tutoringShortOnboarding = '/tutoring/short-onboarding',
  completeSignUp = '/complete-sign-up',
  individualLessonsBooking = '/booking-lesson',
  individualLessonsPricing = '/tutoring/pricing',
  alreadyUsedLink = '/already-used-link',
  pickTutor = '/tutoring/pick-tutor',
  forYou = '/for-you',
  forYouStartOnboarding = '/for-you/start-onboarding',
}

export enum ProfileRoutes {
  profile = '/profile',
  settings = '/profile/settings',
  paymentsAndSubscriptions = '/profile/settings/payments-subscriptions',
  creditBalance = '/profile/settings/tutoring-credits',
  password = '/profile/settings/password',
  email = '/profile/settings/email',
  phone = '/profile/settings/phone',
  personalInfo = '/profile/settings/personal-info',
  websiteLanguage = '/profile/settings/website-language',
  terms = '/profile/settings/terms',
  contactUs = '/contact-us',
  chooseTutoringSubscription = '/profile/payments-subscriptions/choose',
  linkedAccounts = '/profile/settings/linked-accounts',
}

export const BLOG_ROUTE = '/blog'
export const AUTHOR_ROUTE = '/author'
export const EXPERT_ROUTE = '/expert'
export const FAQ_ROUTE = '/faq'
export const PRESS_ROUTE = '/press'

export const FREE_FOR_UKRAINE_ROUTE = '/free-for-ukraine'
export const FREE_FOR_UKRAINE_DEFAULT_ROUTE = '/free-for-ukraine'
export const GROUP_WELCOME_ROUTE = '/group-lessons/welcome'
export const GROUP_LESSONS_SCHEDULE_ROUTE = '/group-lessons/schedule'

export const ONE_X_ONE_CREDITS_CHECKOUT_ROUTE =
  '/tutoring/one-x-one-credits-checkout'
export const ONE_X_ONE_CREDITS_SUCCESS_ROUTE =
  '/tutoring/one-x-one-credits-success'
export const GROUP_CREDITS_CHECKOUT_ROUTE = '/tutoring/group-credits-checkout'
export const GROUP_CREDITS_SUCCESS_ROUTE = '/tutoring/group-credits-success'

export const OUR_TUTORS_ROUTE = '/our-tutors'

export const routesWithFreeForUkButton = [
  PagesRoutes.courses,
  PagesRoutes.myTutor,
  ...Object.values(LandingsRoutes),
  '/classes',
  '/tutors',
  '/lessons',
  '/',
  '/tutors/english-tutors-for-adults',
  '/tutors/english-with-native-speakers',
  '/tutors/private-english-tutor',
  '/tutors/business-english-tutor',
  '/tutors/conversational-english-teacher',
  '/tutors/english-grammar-tutor',
  '/tutors/english-writing-tutor',
  '/tutors/english-literature-tutor',
  '/tutors/british-english-tutor',
  '/tutors/american-english-tutor',
  '/tutors/learn-australian-english',
  '/levels/english-for-beginners',
  '/levels/elementary-english',
  '/levels/pre-intermediate-english',
  '/levels/intermediate-english',
  '/levels/upper-intermediate-english',
  '/levels/advanced-english',
  '/levels/proficiency-english',
]

export const preventRedirectToCourseFrom = [ProfileRoutes.profile]

export const howToPronounceSitemap = '/sitemap/how-to-pronounce'
export const TUTORING_CHECKOUT_ROUTE = '/tutoring/checkout'
export const GROUP_LESSONS_CHECKOUT_ROUTE = '/tutoring/group-checkout'
export const NOT_FOUND_ROUTE = '/404'

export const redirectUrlsByFlowId: Record<string, string> = new Proxy(
  {
    [COURSES_ONBOARDING]: `/${COURSES_ONBOARDING}?screen=0`,
    [LESSONS_ONBOARDING]: `${PagesRoutes.lessonsOnboarding}`,
    [SHORT_ONBOARDING]: `${PagesRoutes.tutoringShortOnboarding}?screen=0`,
    [TUTORING_PRICING]: PagesRoutes.tutoringPricing,
    [INTERNAL_TUTORS]: ProfileRoutes.chooseTutoringSubscription,
    [INTERNAL_SETTINGS]: ProfileRoutes.chooseTutoringSubscription,
  },
  {
    get: (target: Record<string, string>, prop: string) => {
      if (prop in target) return target[prop]

      return PagesRoutes.tutoringShortOnboarding
    },
  }
)

export const disableCtaPopupAllPagesRoutes = [
  `/${COURSES_ONBOARDING}/sign-up`,
  `/${COURSES_ONBOARDING}/overview`,
  '/tutoring/sign-up',
  TUTORING_CHECKOUT_ROUTE,
  GROUP_LESSONS_CHECKOUT_ROUTE,
  '/external-reg',
  '/learning/[slug]',
  '/offer/[slug]',
  ...Object.values(redirectUrlsByFlowId),
  ...Object.values(ProfileRoutes),
  ...Object.values(PagesRoutes),
  LandingsRoutes.howToPronounce,
  FREE_FOR_UKRAINE_DEFAULT_ROUTE,
]

export const ROUTES_DISABLE_LOCALES: string[] = [
  '/how-to-pronounce',
  '/how-to-pronounce/[word]',
  '/quiz/[slug]',
  '/what-part-of-speech',
  '/what-part-of-speech/[slug]',
  '/confusing-words',
  '/confusing-words/[slug]',
  '/sitemap/what-part-of-speech-is',
  '/sitemap/how-to-pronounce',
  '/sitemap/confusing-words',
  '/sitemap/past-tense-of',
]

export const ROUTES_CLIENT_SIDE_REDIRECT_TO_DEFAULT_LOCALE = [
  '/confusing-words',
  '/what-part-of-speech',
  '/english-tutors',
]

export const AUTOLOGIN_ROUTE = '/autologin'

export const CONFUSING_WORDS = '/confusing-words'

export const PAST_TENSE_OF = '/past-tense-of'

export const SYNONYMS_OF = '/synonyms-of'

export const ANTONYMS_OF = '/antonyms-of'

export const TUTOR_INFO_ROUTE = '/tutoring/tutors'
