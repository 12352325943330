import { type LessonItem } from '@_types/pricing_page'

export const lessons25min: Record<number, LessonItem> = {
  4: {
    noTrial: 'tutor_subs_4x25_usd_65.99_30d',
    trial: 'tutor_subs_4x25_usd_65.99_30d_free_7d_trial',
  },
  8: {
    noTrial: 'tutor_subs_8x25_usd_103.99_30d',
    trial: 'tutor_subs_8x25_usd_103.99_30d_free_7d_trial',
  },
  12: {
    noTrial: 'tutor_subs_12x25_usd_131.99_30d',
    trial: 'tutor_subs_12x25_usd_131.99_30d_free_7d_trial',
  },
}

export const lessons50min: Record<number, LessonItem> = {
  4: {
    noTrial: 'tutor_subs_4x50_usd_103.99_30d',
    trial: 'tutor_subs_4x50_usd_103.99_30d_free_7d_trial',
  },
  8: {
    noTrial: 'tutor_subs_8x50_usd_175.99_30d',
    trial: 'tutor_subs_8x50_usd_175.99_30d_free_7d_trial',
  },
  12: {
    noTrial: 'tutor_subs_12x50_usd_239.99_30d',
    trial: 'tutor_subs_12x50_usd_239.99_30d_free_7d_trial',
  },
}

export const lessons90min: Record<number, LessonItem> = {
  4: {
    noTrial: 'tutor_subs_4x90_usd_131.99_30d',
    trial: 'tutor_subs_4x90_usd_131.99_30d_free_7d_trial',
  },
  8: {
    noTrial: 'tutor_subs_8x90_usd_239.99_30d',
    trial: 'tutor_subs_8x90_usd_239.99_30d_free_7d_trial',
  },
  12: {
    noTrial: 'tutor_subs_12x90_usd_339.99_30d',
    trial: 'tutor_subs_12x90_usd_339.99_30d_free_7d_trial',
  },
}

export const plansByDuration: Record<number, Record<number, LessonItem>> = {
  25: lessons25min,
  50: lessons50min,
  90: lessons90min,
}

export const lesson50MinPlans = {
  8: {
    noTrial: 'tutor_subs_8l_159.99USD_30d',
    trial: '',
  },
  6: {
    noTrial: 'tutor_subs_12credits_usd_131.99_28d',
    trial: 'tutor_subs_12credits_usd_131.99_28d_free_7d_trial',
  },
  4: {
    noTrial: 'tutor_subs_8credits_usd_103.99_28d',
    trial: 'tutor_subs_8credits_usd_103.99_28d_free_7d_trial',
  },
  2: {
    noTrial: 'tutor_subs_4credits_usd_65.99_28d',
    trial: 'tutor_subs_4credits_usd_65.99_28d_free_7d_trial',
  },
}

export const lesson50MinUkrPlans = {
  8: {
    noTrial: 'tutor_subs_8credits_UAH_6719.99_28d',
    trial: '',
  },
  6: {
    noTrial: 'tutor_subs_6credits_UAH_5537.99_28d',
    trial: '',
  },
  4: {
    noTrial: 'tutor_subs_4credits_UAH_4363.99_28d',
    trial: '',
  },
  2: {
    noTrial: 'tutor_subs_2credits_UAH_2769.99_28d',
    trial: '',
  },
}
