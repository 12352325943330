import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { CSSProperties, FC, ReactElement, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useAppSelector } from '@reduxStore/hooks'

import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'
import { fontsLibrary } from '@utils/strapiCommons/constants'
import { FontParamsTypes } from '@utils/strapiCommons/types'
import { createCSSFontVariables } from '@utils/strapiCommons/utils'

import { DescriptionProps } from '@features/LandingBuilder/types/props'

import FitSizeText from '@elements/FitSizeText/FitSizeText'

import styles from './hero_description_block.module.scss'

type Props = {
  title?: string
  titleFontParams?: FontParamsTypes
  description: DescriptionProps
  className?: string
  style?: CSSProperties
  isFitSizeText?: boolean
  customLabels?: {
    readMore?: string | ReactElement
    showLess?: string | ReactElement
  }
}

const CUSTOM_FONT_SIZE = 60

const HeroDescriptionBlock: FC<Props> = ({
  title,
  titleFontParams,
  description,
  className,
  customLabels,
  style,
  isFitSizeText,
}) => {
  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false)

  const customBreakpoint = useMediaQuery({
    maxHeight: 780,
    minWidth: 1200,
    maxWidth: 1430,
  })

  const titleCSSFontVariables = createCSSFontVariables({
    fontSize:
      titleFontParams?.fontSize || customBreakpoint
        ? CUSTOM_FONT_SIZE
        : fontsLibrary.Nekst70.fontSize,
    fontSizeMobile:
      titleFontParams?.fontSizeMobile || fontsLibrary.Nekst70.fontSizeMobile,
  })

  const descriptionCSSFontVariables = createCSSFontVariables({
    fontSize:
      description?.fontParams?.fontSize || fontsLibrary.Manrope24.fontSize,
    fontSizeMobile:
      description?.fontParams?.fontSizeMobile ||
      fontsLibrary.Manrope24.fontSizeMobile,
  })

  const firstClick = useRef(true)
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )

  const { LANDING_PATH } = useLandingInfo()

  const toggleShowDescription = () => {
    setIsDescriptionOpened((prevState) => !prevState)

    if (amplitudeInited && firstClick.current) {
      sendAnalytics({
        eventName: 'gen_clicked_show_full_content',
        data: { page_path: LANDING_PATH, place: 'hero', redirect: 'false' },
      })
      firstClick.current = false
    }
  }

  const leftContentClassNames = classNames(styles.left_content, className, {
    [styles.description_opened]: isDescriptionOpened,
  })

  return (
    <div className={leftContentClassNames} style={style}>
      {isFitSizeText ? (
        <FitSizeText
          text={title}
          Tag="h1"
          className={styles.title}
          style={titleCSSFontVariables}
        />
      ) : (
        <h1 className={styles.title} style={titleCSSFontVariables}>
          {title}
        </h1>
      )}
      {description?.element && !isDescriptionOpened && description.element}
      <div
        className={styles.description_block}
        style={descriptionCSSFontVariables}
      >
        <div className={styles.indent_block} />
        {description?.secondText ? (
          <>
            <span
              className={styles.description_showed}
              dangerouslySetInnerHTML={{
                __html: description.firstText || '',
              }}
            />
            <span className={styles.dot}>..</span>
            <span
              aria-hidden="true"
              className={styles.show_label}
              onClick={toggleShowDescription}
            >
              {customLabels?.readMore || <Trans>Read&nbsp;more</Trans>}
            </span>
            <span
              className={styles.description_hidden}
              dangerouslySetInnerHTML={{
                __html: description.secondText,
              }}
            />
            <span
              aria-hidden="true"
              className={styles.hide_label}
              onClick={toggleShowDescription}
            >
              {customLabels?.showLess || <Trans>Show&nbsp;less</Trans>}
            </span>
          </>
        ) : (
          <span
            className={styles.description_showed}
            dangerouslySetInnerHTML={{
              __html: description?.firstText || '',
            }}
          />
        )}
      </div>
    </div>
  )
}

export default HeroDescriptionBlock
