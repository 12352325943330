import { type User, getAuth, onAuthStateChanged } from 'firebase/auth'
import createClient, {
  type FetchOptions,
  type FetchResponse,
  type Middleware,
  type MiddlewareRequest,
} from 'openapi-fetch'
import {
  type FilterKeys,
  type HasRequiredKeys,
  type PathsWithMethod,
} from 'openapi-typescript-helpers'

import { USER_TIMEZONE } from '@constants_folder/common'

import initFirebase from '@utils/firebase'

import { type paths } from './schema'

export type MaybeOptionalInit<P, M extends keyof P> = HasRequiredKeys<
  FetchOptions<FilterKeys<P, M>>
> extends never
  ? [(FetchOptions<FilterKeys<P, M>> | undefined)?]
  : [FetchOptions<FilterKeys<P, M>>]

const getCurrentUser = (): Promise<User | null> =>
  new Promise((resolve) => {
    const firebaseApp = initFirebase()
    const auth = getAuth(firebaseApp)

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user)
        unsubscribe()
      }
    })
  })

const getToken = async () => {
  const user = await getCurrentUser()

  if (!user) {
    return ''
  }

  const token = await user.getIdToken()

  return token
}

const middlewareCallBack: Middleware = {
  onRequest: async (req: MiddlewareRequest) => {
    const token = await getToken()

    req.headers.set('Authorization', `Bearer ${token}`)
    req.headers.set('x-time-location', USER_TIMEZONE)

    return req
  },
}

class HttpClient {
  private instance

  constructor(
    middleware: Middleware,
    baseUrl: string = process.env.NEXT_PUBLIC_API_HOST || ''
  ) {
    this.instance = createClient<paths>({ baseUrl })
    this.instance.use(middleware)
  }

  async get<
    P extends PathsWithMethod<paths, 'get'>,
    I extends MaybeOptionalInit<paths[P], 'get'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['get'], I[0]>> {
    const result = await this.instance.GET(url, ...init)

    return result
  }

  async post<
    P extends PathsWithMethod<paths, 'post'>,
    I extends MaybeOptionalInit<paths[P], 'post'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['post'], I[0]>> {
    const result = await this.instance.POST(url, ...init)
    return result
  }

  async put<
    P extends PathsWithMethod<paths, 'put'>,
    I extends MaybeOptionalInit<paths[P], 'put'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['put'], I[0]>> {
    const result = await this.instance.PUT(url, ...init)
    return result
  }

  async patch<
    P extends PathsWithMethod<paths, 'patch'>,
    I extends MaybeOptionalInit<paths[P], 'patch'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['patch'], I[0]>> {
    const result = await this.instance.PATCH(url, ...init)
    return result
  }
}

const coursesHttpClient = new HttpClient(
  middlewareCallBack,
  process.env.NEXT_PUBLIC_API_COURSES_HOST
)

const tutorsHttpClient = new HttpClient(
  middlewareCallBack,
  process.env.NEXT_PUBLIC_API_TUTORS
)

const mainHttpClient = new HttpClient(
  middlewareCallBack,
  process.env.NEXT_PUBLIC_API_HOST
)

export { coursesHttpClient, mainHttpClient, tutorsHttpClient }

export default HttpClient
